import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {common, getapi} from '../common/functions';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const theme = createTheme();




  class Login extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
      };
      this.handleSubmit = this.handleSubmit.bind(this);
    }
    async handleSubmit(event){
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const form  = {};
      form.username = data.get('username');
      form.password =data.get('password');
  
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(form),
      };
      const url = common.api + 'rest-auth/login/';
      const response = await fetch(url, requestOptions);
      const data1 = await response.json();
      this.check_status(data1, response)
    };
    async check_status(data, response){
      if(response.status===200){
          var token = data.key;
          const current = new Date();
          var nextYear = new Date();
          nextYear.setFullYear(current.getFullYear() + 1);
          cookies.set('token', token, {
              path: '/',
              expires: nextYear,
          })
          window.location.href = "../payments";

      }
      else{this.setState({message:'Неправильный логин или пароль'})}
  }
    render() {
  
      return (
        <div className='block d-flex flex-column justify-content-center'>
            <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={this.handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    autoComplete="username"
                    autoFocus
                    />
                    <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    />
                    <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    >
                    Sign In
                    </Button>
                </Box>
                </Box>
            </Container>
            </ThemeProvider>
        </div>
      );
    }
  }
  export default Login;
