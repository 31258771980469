import * as React from 'react';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {common, getapi, logout, printer} from '../common/functions';
import Cookies from 'universal-cookie';
import {Link} from "react-router-dom";
import Tabler from './tabler'
import Header from '../common/header';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
  } from '@mui/material';
const cookies = new Cookies();
const theme = createTheme();


  class Details extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        search:'',
        data: [],
        filteredData: [],
        filters: {}
      };
      this.handleSubmit = this.handleSubmit.bind(this)
    }

    async componentDidMount() {
      
    }
    async handleSubmit(){
        var payments = await getapi('api/payment/search?search=' + this.state.search);
        payments = payments.map((payment) =>{
          payment.date = new Date(payment.date).toLocaleDateString();
          return payment;
        })
        this.setState({data:payments});
    }
    // handleFilterChange = (event, columnIndex) => {
    //     const { value } = event.target;
    //     const { data, filters } = this.state;
    //     const filterKey = `filter_${columnIndex}`;
    //     const filteredData = data.filter((row) =>
    //       Object.values(row).some((cell, index) => {
    //         if (!filters.hasOwnProperty(`filter_${index}`)) {
    //           return true;
    //         }
    //         const filterValue = filters[`filter_${index}`].toLowerCase();
    //         return cell.toString().toLowerCase().includes(filterValue);
    //       })
    //     );
    //     filters[filterKey] = value;
    //     this.setState({ filteredData, filters });
    //   };
    
    render() {
        var {payments, filteredData, data} = this.state;
      return (
        <div>
            <Header/>
            <div className='container mb-4 d-flex flex-column'>
                <div className='searchBar d-flex flex-nowrap mt-2 mb-2 noprint'>
                    <TextField id="outlined-basic" label="ID, имя учителя или ученика" variant="outlined" onChange={(event) => {this.setState({search:event.target.value})}}/>
                    <Button variant="contained" className='ms-4' onClick={this.handleSubmit}>Поиск</Button>
                </div>
                <div className='searchBody d-flex flex-column'>
                {/* <TextField
                    label="Filter"
                    onChange={this.handleFilterChange}
                    style={{ margin: "20px 0" }}
                    /> */}
                    {/* <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell>№</TableCell>
                            <TableCell>Дата</TableCell>
                            <TableCell>Имя студента</TableCell>
                            <TableCell>Имя учителя</TableCell>
                            <TableCell>Уровень</TableCell>
                            <TableCell>Сумма</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {filteredData.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell ><Link to={'../details/'+row.id}>{row.id}</Link></TableCell>
                                <TableCell >{row.date}</TableCell>
                                <TableCell >{row.student}</TableCell>
                                <TableCell >{row.teacher_name}</TableCell>
                                <TableCell >{row.course_name}</TableCell>
                                <TableCell >{row.sum}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer> */}
                  {
                    data&&
                    <Tabler data={data}/>
                  }
                </div>
            </div>
        </div>
      );
    }
  }
  export default Details;
