import * as React from 'react';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {common, getapi, logout, printer} from '../common/functions';
import Cookies from 'universal-cookie';
import {Link} from "react-router-dom";
import Tabler from './tabler'
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
  } from '@mui/material';
  import Header from '../common/header';
const cookies = new Cookies();
const theme = createTheme();


  class Details extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        starts:'',
        ends:'',
        data: [],
      };
      this.handleSubmit = this.handleSubmit.bind(this)
    }

    async componentDidMount() {
    }
    async handleSubmit(){
        const {starts,ends} = this.state;
        var payments = await getapi('api/payment/get?starts=' + starts + '&ends=' + ends);

        payments = payments.map((payment) =>{
          payment.date = new Date(payment.date).toLocaleDateString();
          return payment;
        })
        this.setState({data:payments});
    }
    render() {
        var {data} = this.state;
      return (
        <div>
            <Header/>
            <div className='container mb-4 d-flex flex-column'>
                <div className='searchBar d-flex flex-nowrap mt-2 mb-2'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                        <div className="d-flex flex-nowrap">
                            <DatePicker label="От" onChange={(value)=>{this.setState({starts:value.format('YYYY-MM-DD')});}}/>
                            <DatePicker label="До" onChange={(value)=>{this.setState({ends:value.format('YYYY-MM-DD')});}}/>
                        </div>
                    </DemoContainer>
                </LocalizationProvider>
                    <Button variant="contained" className='ms-4' onClick={this.handleSubmit}>Поиск</Button>
                </div>
                <div className='searchBody d-flex flex-column'>
                {
                    data&&
                    <Tabler data={data}/>
                }
                </div>
            </div>
        </div>
      );
    }
  }
  export default Details;
