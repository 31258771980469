import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {common, getapi, messager, logout} from '../common/functions';
import Cookies from 'universal-cookie';
import {read, utils} from 'xlsx'
import Header from '../common/header';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Swal from 'sweetalert2'

const cookies = new Cookies();
const theme = createTheme();

const printableColumns = ['Дата оплаты', 'Имя', 'Фамилия', 'Уровень', 'Номер квитанции', 'Названия Офиса', 'Тип оплаты', 'Сумма оплаты' ]


  class Main extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        json:[],
        uniqueKeys:[],
      };
      this.readUploadFile = this.readUploadFile.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }

  excelTimeSerialToDate(excelTimeSerial) {
  const jsDate = new Date((excelTimeSerial - (25567 + 1)) * 86400 * 1000); // convert Excel time serial to JavaScript date
  const date = jsDate.getDate().toString().padStart(2, '0');
  const month = (jsDate.getMonth() + 1).toString().padStart(2, '0');
  const year = jsDate.getFullYear();
  return `${date}/${month}/${year}`;
  }
    readUploadFile(e){
      e.preventDefault();
      if (e.target.files) {
          const reader = new FileReader();
          reader.onload = (e) => {
              const data = e.target.result;
              const workbook = read(data, { type: "array" });
              const sheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[sheetName];
              var json = utils.sheet_to_json(worksheet);
              json.map(data =>
                data['Дата оплаты'] = this.excelTimeSerialToDate(data['Дата оплаты']))
              const keys = json.map(obj => Object.keys(obj)).flat();
              const uniqueKeys = [...new Set(keys)];
              this.setState({json:json, uniqueKeys:uniqueKeys});
              this.props.getPrintDetails(json);
              
          };
          reader.readAsArrayBuffer(e.target.files[0]);
      }
    }

    componentDidMount() {
      const printable = document.querySelector('.printable')
      printable.classList.add('print-page');
    }

    componentWillUnmount() {
     const printable = document.querySelector('.printable')
      printable.classList.remove('print-page');
    }
    async handleSubmit(e){
      e.preventDefault();
    }

    handlePrint = (json) => {
      window.print();
    }
    render() {
      const {json, uniqueKeys} = this.state;
      return (
        <div>
          <Header/>
          <div className='block d-flex flex-column justify-content-center container'>
              <ThemeProvider theme={theme}>
              <Container component="main" maxWidth="xs">
                  <CssBaseline />
                  <Box
                  sx={{
                      marginTop: 8,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                  }}
                  >
                  <Typography component="h1" variant="h5">
                      Please upload the .xlsx file
                  </Typography>
                  <Box component="form" onSubmit={this.handleSubmit} noValidate sx={{ mt: 1 }}>
                  <Button
                      variant="outlined"
                      component="label"
                      fullWidth
                      sx={{ mt: 3, mb: 1 }}
                      onChange={this.readUploadFile}
                    >
                      Upload File
                      <input
                        type="file"
                        hidden
                      />
                    </Button>
                    {
                      json.length>0 && (
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      endIcon={<SendIcon />}
                      onClick={(json)=>this.handlePrint(json)}
                      >
                      Print
                    </Button>
                      )
                    }
                  </Box>
                  </Box>
              </Container>
              </ThemeProvider>

              <table>
                <thead>
                  <tr>
                    {
                      json.length > 0 &&
                      <th>№</th>
                    }
                    {
                    uniqueKeys.map((key, i) => (
                      <th key={i}>{key}</th>
                    ))
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    json.map((j, i) => (
                      <tr key={i+1}>
                        <th>{i+1}</th>
                        {
                          uniqueKeys.map((keys, i) => (
                            <th key={i}>{j[keys]}</th>
                          ))
                        }
                      </tr>
                    ))
                  }
              </tbody>
              </table>
          </div>
            <div className="printable">
                {json.map((j, k) => (
                  <div className="print-card" key={k}>
                    <img src='fast.png' alt='log' />
                  {printableColumns.map((key, i)=> (
                        <div className='card-content' key={i}> 
                          <p>{key}</p>
                          {key === 'Сумма оплаты' ? <p className='price'>{j[key]} сум</p> : <p>{j[key]}</p>}
                        </div>
                  ))}
                  </div>
                ))}
              </div>
        </div>
      );
    }
  }
  export default Main;
