import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {common, getapi, messager, logout} from '../common/functions';
import Cookies from 'universal-cookie';
import {read, utils} from 'xlsx'

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Swal from 'sweetalert2'
import {Link} from "react-router-dom";

const cookies = new Cookies();
const theme = createTheme();

  class Header extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
      };

    }
    componentDidMount() {
        var token= cookies.get('token');
        if(!token && window.location.pathname!='/'){
            window.location.href = "../";
        }
    }
    render() {
      return (
        <div className='noprint'>
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
              <Toolbar>
                {/* <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                >
                </IconButton> */}
                <Button color="inherit" onClick={()=>(logout())}>Выход</Button>
                <Button color="inherit" component={Link} to="/payments">Оплата</Button>
                <Button color="inherit" component={Link} to="/details">Подробности</Button>
                <Button color="inherit" component={Link} to="/period">Период</Button>
              </Toolbar>
            </AppBar>
          </Box>
        </div>
      );
    }
  }
  export default Header;
