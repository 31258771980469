import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {common, getapi, logout, printer} from '../common/functions';
import Header from '../common/header';
import Cookies from 'universal-cookie';
import {read, utils} from 'xlsx'
import Swal from 'sweetalert2'

const cookies = new Cookies();
const theme = createTheme();

  class Payments extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        base: [],
        form: {},
        clicked: false,
      };
      this.handleSubmit = this.handleSubmit.bind(this);
      this.divRef = React.createRef();
    }

    async componentDidMount() {
        const base = await getapi('api/payment/main');
        base.sums = base.sums.map(sum => ({
          label : ''+sum.sum,
          id : sum.sum,
      }))
        base.courses = base.courses.map(course => ({
            label : course.course,
            id : course.id,
        }))
        base.days = base.days.map(day => ({
            label : day.days,
            id : day.id,
        }))
        base.offices = base.offices.map(office => ({
          label : office.office,
          id : office.id,
        }))
        base.typesOfPayment = base.typesOfPayment.map(type => ({
          label : type.type,
          id : type.id,
        }))
        base.teachers = base.teachers.map(teacher => ({
          label : teacher.teacher,
          id : teacher.id,
        }))
        base.discounts = base.discounts.map(discount => ({
          label : ''+discount.discount,
          id : discount.id,
        }))
        base.times = base.times.map(time => ({
          label : ''+time.time,
          id : time.id,
        }))
        base.tens = base.tens.map(ten => ({
          label : ten.tens,
          id : ten.id,
        }))
        this.setState({base:base})
    }
    handleChange = (event) => {
      var form = this.state.form;
      form[event.target.name] = event.target.value;
      this.setState({form:form});
    }
    handleAutoChange = (key, value) => {
      var form = this.state.form;
      form[key] = value ? value.id : null;
      this.setState({form:form});
    }
    async handleSubmit (){
      this.setState({clicked:true});
      var data = this.state.form;
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+common.token,
        },
        body: JSON.stringify(data),
      };
      const url = common.api + 'api/payment/';
      const response = await fetch(url, requestOptions);
      const response_code = response.status;
      const data_response = await response.json();
      if(response_code===201){
        Swal.fire({
          title: 'Do you want to print?',
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: 'Print',
          // denyButtonText: `Don't print`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            // this.setState({print:data_response}, ()=>{
              printer(data_response);
            // })
            Swal.fire('Printed!', '', 'success')
            window.location.href = "../payments";
          } else if (result.isDenied) {
            Swal.fire('Not printed', '', 'info')
          }
        })
      }
      else{
        Swal.fire({
          icon: 'error',
          title: response_code,
          text: JSON.stringify(data_response),
        }).then((result) => {
          if (result.isConfirmed) {
            // window.location.href = "../payments";
          } 
        })        
      }
    }
    render() {
        const {courses, days, offices, typesOfPayment, teachers, sums, discounts, times, tens} = this.state.base;
        const {print, clicked} = this.state;
      return (
        <div>
            <Header/>
            <div className='container mb-4'>
              <div className='form d-flex flex-column noprint'>
                  <TextField required id="outlined-required" margin="normal" label="Name of student" name='student' autoFocus={true} onChange={this.handleChange}/>
                  {sums&&<Autocomplete id="combo-box-demo" options={sums} freeSolo onChange={(event, value) => {this.handleAutoChange('sum', value)}} renderInput={(params) => <TextField {...params} required label="SUM"  margin="normal" name='sum' onChange={this.handleChange}/>}/>}
                  {discounts&&<Autocomplete id="combo-box-demo" options={discounts}  onChange={(event, value) => {this.handleAutoChange('discount', value)}} renderInput={(params) => <TextField {...params} label="Discount"  margin="normal" name='discount'/>}/>}
                  {courses&&<Autocomplete id="combo-box-demo" options={courses}  onChange={(event, value) => {this.handleAutoChange('course', value)}} renderInput={(params) => <TextField required {...params} label="Course"  margin="normal" name='course'/>}/>}
                  {teachers&&<Autocomplete id="combo-box-demo" options={teachers}  onChange={(event, value) => {this.handleAutoChange('teacher', value)}} renderInput={(params) => <TextField required {...params} label="Teacher"  margin="normal" name='teacher'/>}/>}
                  {days&& <Autocomplete id="combo-box-demo" options={days}  onChange={(event, value) => {this.handleAutoChange('days', value)}} renderInput={(params) => <TextField required {...params} label="Days"  margin="normal" name='days'/>}/>}
                  {tens&& <Autocomplete id="combo-box-demo" options={tens}  onChange={(event, value) => {this.handleAutoChange('tens', value)}} renderInput={(params) => <TextField {...params} label="Pair(10)"  margin="normal" name='tens'/>}/>}
                  {times&& <Autocomplete id="combo-box-demo" options={times}  onChange={(event, value) => {this.handleAutoChange('time', value)}} renderInput={(params) => <TextField {...params} label="Time"  margin="normal" name='times'/>}/>}
                  {offices&&<Autocomplete id="combo-box-demo" options={offices}  onChange={(event, value) => {this.handleAutoChange('office', value)}} renderInput={(params) => <TextField required {...params} label="Office"  margin="normal" name='office'/>}/>}
                  {typesOfPayment&&<Autocomplete id="combo-box-demo" options={typesOfPayment}  onChange={(event, value) => {this.handleAutoChange('type', value)}} renderInput={(params) => <TextField required {...params} label="Type of payment"  margin="normal" name='type'/>}/>}
                  <TextField id="outlined-required" margin="normal" label="comment" name='comment' onChange={this.handleChange}/>
                  <Button variant="outlined" onClick={this.handleSubmit} margin="normal" disabled={clicked}>Submit</Button>
              </div>
            </div>
        </div>

      );
    }
  }
  export default Payments;
