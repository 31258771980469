import React, { Component } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { saveAs } from 'file-saver';
import {Link} from "react-router-dom";
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';
import { formatValue } from '../common/functions';
class FilterableTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {},
    };
  }
  handleFilterChange = (columnId) => (event) => {
    const value = event.target.value;
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        [columnId]: value,
      },
    }));
  };
  downloadExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "DataSheet.xlsx");
  };
  render() {
    const { data } = this.props;
    const { filters } = this.state;
  
    const filteredData = data.filter((row) => {
      for (const columnId in filters) {
        if (filters[columnId]) {
          const cellValue = String(row[columnId]).toLowerCase();
          const filterValue = filters[columnId].toLowerCase();
          if (!cellValue.includes(filterValue)) {
            return false;
          }
        }
      }
      return true;
    });
  
    const filteredSum = filteredData.reduce((acc, row) => acc + row.sum, 0);
  
    const toExcel = filteredData.map(({ id, course_name, tens_name, days_name,times_name, teacher_name, type_name, office_name, date, sum,discount_name, student, comment }) => ({ id, course_name, tens_name, days_name,times_name, teacher_name, type_name, office_name, date, sum,discount_name, student, comment  }));
  
    return (
      <div className='printable'>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    placeholder='Дата'
                    onChange={this.handleFilterChange('date')}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    placeholder='Имя студента'
                    onChange={this.handleFilterChange('student')}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    placeholder='Имя учителя'
                    onChange={this.handleFilterChange('teacher_name')}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    placeholder='Уровень'
                    onChange={this.handleFilterChange('course_name')}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    placeholder='Пара'
                    onChange={this.handleFilterChange('tens_name')}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    placeholder='Сумма'
                    onChange={this.handleFilterChange('sum')}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    placeholder='Скидка'
                    onChange={this.handleFilterChange('discount_name')}
                  />
                </TableCell>
                <TableCell>
                 Комментарий
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((row) => (
                <TableRow key={row.id}>
                  <TableCell><Link to={'../details/'+row.id}>{row.id}</Link></TableCell>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.student}</TableCell>
                  <TableCell>{row.teacher_name}</TableCell>
                  <TableCell>{row.course_name}</TableCell>
                  <TableCell>{row.tens_name}</TableCell>
                  <TableCell>{row.sum}</TableCell>
                  <TableCell>{row.discount_name}</TableCell>
                  <TableCell>{row.comment}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className='d-flex flex-nowrap justify-content-between py-4'>
          <span>Итого:</span>
          <span>{formatValue(filteredSum)}</span>
        </div>
        <Button variant="outlined" onClick={()=>this.downloadExcel(toExcel)} margin="normal">Download As Excel</Button>
      </div>
    );
  }
}
export default FilterableTable;