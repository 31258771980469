import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {common, getapi, messager, logout} from '../common/functions';
import Cookies from 'universal-cookie';
import {read, utils} from 'xlsx'

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Swal from 'sweetalert2'


const cookies = new Cookies();
const theme = createTheme();




  class Print extends React.Component {
    constructor(props) {
      super(props);
      this.state = {

      };

    }

    componentDidMount() {
      document.table.classList.add('print-page');
    }

    componentWillUnmount() {
      document.table.classList.remove('print-page');
    }

    render() {
      const {json, uniqueKeys} = this.state;
      return (
        <div>
         
        </div>
      );
    }
  }
  export default Print;
