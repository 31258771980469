import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './common/main.css'
import React from 'react';
import Cookies from 'universal-cookie';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Login from './views/login';
import Main from './views/main';
import Print from './views/print';
import Payments from './views/payment';
import Details from './views/details';
import Detail from './views/detail';
import Period from './views/period';
import Header from './common/header';
import {logout} from './common/functions';

const cookies = new Cookies();
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      json:[],
      isAuthenticated:false,
    };
  }
  render() {
var {token} = this.state;
    return (
      <Router>
          <Route exact path="/" component={Login}/>
          {/* <Route path="/main/" render={(props) => <Main {...props} />}/> */}
          {/* <Route path="/print/" render={(props) => <Print {...props} state={this.state} />}/> */}
          <Route path="/payments/" render={(props) => <Payments {...props} state={this.state} />}/>
          <Route exact path="/details/" render={(props) => <Details {...props} state={this.state} />}/>
          <Route exact path="/details/:id" render={(props) => <Detail {...props} state={this.state} />}/>
          <Route exact path="/period/" render={(props) => <Period {...props} state={this.state} />}/>
    </Router>
    );
  }
}
export default App;
