import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Autocomplete from '@mui/material/Autocomplete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {common, getapi, logout, printer} from '../common/functions';
import Cookies from 'universal-cookie';
import {read, utils} from 'xlsx'
import Swal from 'sweetalert2'
import {Link} from "react-router-dom";
import Tabler from './tabler'
import Header from '../common/header';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
  } from '@mui/material';
const cookies = new Cookies();
const theme = createTheme();


  class Detail extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        data:[],
      };
    }

    async componentDidMount() {
        const { match } = this.props;
        const { id } = match.params;
        const payment = await getapi('api/payment/detail/' + id);
        this.setState({payment:payment[0], data:payment});
    }
    async handleSubmit(){

    }   
    render() {
        const {payment, data} = this.state;
      return (
        <div>
          <Header/>
          <div className='container mt-4 mb-4 d-flex flex-column'>
              <TableContainer component={Paper} className='mb-4'>
                  <Table>
                      <TableHead>
                      <TableRow>
                          <TableCell>№</TableCell>
                          <TableCell>Дата</TableCell>
                          <TableCell>Имя студента</TableCell>
                          <TableCell>Имя учителя</TableCell>
                          <TableCell>Уровень</TableCell>
                          <TableCell>Сумма</TableCell>
                          <TableCell>Скидка</TableCell>
                          <TableCell>Оффис</TableCell>
                          <TableCell>Админ</TableCell>
                      </TableRow>
                      </TableHead>
                      {
                          payment&&
                          <TableBody>
                              <TableRow>
                                  <TableCell >{payment.id}</TableCell>
                                  <TableCell >{new Date(payment.date).toLocaleDateString()}</TableCell>
                                  <TableCell >{payment.student}</TableCell>
                                  <TableCell >{payment.teacher_name}</TableCell>
                                  <TableCell >{payment.course_name}</TableCell>
                                  <TableCell >{payment.sum}</TableCell>
                                  <TableCell >{payment.discount_name}</TableCell>
                                  <TableCell >{payment.office_name}</TableCell>
                                  <TableCell >{payment.user_name}</TableCell>
                              </TableRow>
                          </TableBody>
                      }
                  </Table>
              </TableContainer>
              <div className='mb-4'>{payment&& payment.comment}</div>
              <Button variant="contained" className='ms-4' onClick={()=>(printer(payment))}>Печать</Button>
          </div>
        </div>
      );
    }
  }
  export default Detail;
